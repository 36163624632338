<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<style>

</style>

<script>

export default {
  name: "Layout",
  components: {}
}
</script>
